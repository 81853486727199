<template>
  <section>
    <!-- <point-settings-popup /> -->

    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- one -->
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-trophy text-22px text-oOrange"></i>
          <span class="pl-3">
            {{ $t('components.settingsManagement.points.headline.points') }}
          </span>
        </div>
        <div></div>
      </div>
      <!-- two -->
      <div class="points-table-title">
        <div class="px-2 font-bold w-70p text-14px">
          {{ $t('components.settingsManagement.points.points.goodBehaviour') }}
        </div>
        <div class="px-2 font-bold w-30p text-14px">
          {{ $t('components.settingsManagement.points.points.points') }}
        </div>
      </div>
      <!-- three -->
      <div v-for="(item, index) in settings.good_points" :key="`good-${index}`">
        <SmartPointInput
          :rowId="`good_${item.id}`"
          :title="item.title"
          placeholder="Enter
        your point"
          :type="text"
          :point="item.point"
          :isEdit="item.isEdit"
          @save="onSave(item.id, $event)"
        />
      </div>
      <!-- four -->
      <div class="mt-10 points-table-title">
        <div class="px-2 font-bold w-70p text-14px">
          {{ $t('components.settingsManagement.points.points.badBehaviour') }}
        </div>
        <div class="px-2 font-bold w-30p text-14px">
          {{ $t('components.settingsManagement.points.points.points') }}
        </div>
      </div>
      <!-- five -->
      <div v-for="(item, index) in settings.bad_points" :key="`bad-${index}`">
        <SmartPointInput
          :rowId="`bad_${item.id}`"
          :title="item.title"
          placeholder="Enter
        your point"
          :type="text"
          :point="item.point"
          :isEdit="item.isEdit"
          @save="onSave(item.id, $event)"
        />
      </div>

      <!-- reward group -->
      <div class="flex justify-between mt-10">
        <div>
          <div class="flex items-center">
            <i class="fas fa-award text-oOrange text-22px"></i>
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              {{
                $t(
                  'components.settingsManagement.points.headline.rewardPointGroups'
                )
              }}
            </div>
          </div>
        </div>
        <div
          class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end"
        >
          <div v-if="!isRewardPointEdit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handleRewardPointEdit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
        <div v-if="isRewardPointEdit" class="flex ml-3 mt-3 mb-4">
          <anchor-button
            @click="handleRewardPointCancel"
            :text="$t('components.stepNavigation.cancel')"
            width="6rem"
          />
          <anchor-button
            @click="updateRewardPointGroups"
            :text="$t('components.stepNavigation.save')"
            variant="success"
            width="6rem"
            class="ml-4"
          />
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-6 gap-x-10 gap-y-4 md:grid-cols-2 lg:grid-cols-2"
        v-if="rewardPointGroups.length > 0"
      >
        <section class="border-1 p-4">
          <input-label
            :text="
              $t(
                'components.settingsManagement.points.rewardPointGroups.silver'
              )
            "
          />
          <div class="flex items-center">
            <text-input :placeholder="`0`" class="pr-2" disabled />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="rewardPointGroups[0].end"
              v-on:input="handleRewardPointGroupInputChange"
              class="pl-2"
              :disabled="!isRewardPointEdit"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>{{
                $t(
                  'components.settingsManagement.points.rewardPointGroups.benefits'
                )
              }}</span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddBenefit(0)"
              >
                <i class="fas fa-plus-circle" style="color:black;"></i>
              </div>
            </div>
            <div
              class="flex items-center"
              v-for="(item, index) in rewardPointGroups[0].benefits"
              :key="index"
            >
              <div class="flex gap-2 items-center w-11/12">
                <div class="w-full">
                  <AppInput
                    v-model="rewardPointGroups[0].benefits[index]"
                    type="text"
                    :name="`Silver Benefit ${index}`"
                    rules=""
                    :disabled="!isRewardPointEdit"
                  />
                </div>
              </div>

              <div
                class="flex justify-center w-1/12 item-center -mt-4 ml-2"
                v-if="isRewardPointEdit"
              >
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveBenefit(0, index)"
                >
                  <i class="fas fa-minus-circle" style="color:#d90a20;"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="border-1 p-4">
          <input-label
            :text="
              $t('components.settingsManagement.points.rewardPointGroups.gold')
            "
          />
          <div class="flex items-center">
            <text-input
              :placeholder="`${rewardPointGroups[1].start}`"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="rewardPointGroups[1].end"
              v-on:input="handleRewardPointGroupInputChange"
              class="pl-2"
              :disabled="!isRewardPointEdit"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>{{
                $t(
                  'components.settingsManagement.points.rewardPointGroups.benefits'
                )
              }}</span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddBenefit(1)"
              >
                <i class="fas fa-plus-circle" style="color:black;"></i>
              </div>
            </div>
            <div
              class="flex items-center"
              v-for="(item, index) in rewardPointGroups[1].benefits"
              :key="index"
            >
              <div class="flex gap-2 items-center w-11/12">
                <div class="w-full">
                  <AppInput
                    v-model="rewardPointGroups[1].benefits[index]"
                    type="text"
                    :name="`Gold Benefit ${index}`"
                    rules=""
                    :disabled="!isRewardPointEdit"
                  />
                </div>
              </div>

              <div
                class="flex justify-center w-1/12 item-center -mt-4 ml-2"
                v-if="isRewardPointEdit"
              >
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveBenefit(1, index)"
                >
                  <i class="fas fa-minus-circle" style="color:#d90a20;"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="border-1 p-4">
          <input-label
            :text="
              $t(
                'components.settingsManagement.points.rewardPointGroups.diamond'
              )
            "
          />
          <div class="flex items-center">
            <text-input
              :placeholder="`${rewardPointGroups[2].start}`"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="rewardPointGroups[2].end"
              v-on:input="handleRewardPointGroupInputChange"
              class="pl-2"
              :disabled="!isRewardPointEdit"
            />
          </div>
          <div class="flex flex-col">
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>{{
                $t(
                  'components.settingsManagement.points.rewardPointGroups.diamond'
                )
              }}</span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddBenefit(2)"
              >
                <i class="fas fa-plus-circle" style="color:black;"></i>
              </div>
            </div>
            <div
              class="flex items-center"
              v-for="(item, index) in rewardPointGroups[2].benefits"
              :key="index"
            >
              <div class="flex gap-2 items-center w-11/12">
                <div class="w-full">
                  <AppInput
                    v-model="rewardPointGroups[2].benefits[index]"
                    type="text"
                    :name="`Diamond Benefit ${index}`"
                    rules=""
                    :disabled="!isRewardPointEdit"
                  />
                </div>
              </div>

              <div
                class="flex justify-center w-1/12 item-center -mt-4 ml-2"
                v-if="isRewardPointEdit"
              >
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveBenefit(2, index)"
                >
                  <i class="fas fa-minus-circle" style="color:#d90a20;"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="border-1 p-4">
          <input-label
            :text="
              $t(
                'components.settingsManagement.points.rewardPointGroups.platinum'
              )
            "
          />
          <div class="flex items-center">
            <text-input
              :placeholder="`${rewardPointGroups[3].start}`"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input :placeholder="`∞`" class="pl-2" disabled />
          </div>
          <div class="flex flex-col">
            <div class="flex items-center my-5 text-sm font-bold text-gray-500">
              <span>{{
                $t(
                  'components.settingsManagement.points.rewardPointGroups.benefits'
                )
              }}</span>
              <div
                class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                @click="onAddBenefit(3)"
              >
                <i class="fas fa-plus-circle" style="color:black;"></i>
              </div>
            </div>
            <div
              class="flex items-center"
              v-for="(item, index) in rewardPointGroups[3].benefits"
              :key="index"
            >
              <div class="flex gap-2 items-center w-11/12">
                <div class="w-full">
                  <AppInput
                    v-model="rewardPointGroups[3].benefits[index]"
                    type="text"
                    :name="`Platinum Benefit ${index}`"
                    rules=""
                    :disabled="!isRewardPointEdit"
                  />
                </div>
              </div>

              <div
                class="flex justify-center w-1/12 item-center -mt-4 ml-2"
                v-if="isRewardPointEdit"
              >
                <div
                  class="col-span-1 cursor-pointer focus:text-gray-400"
                  @click="onRemoveBenefit(3, index)"
                >
                  <i class="fas fa-minus-circle" style="color:#d90a20;"></i>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- end: reward group -->
    </div>
  </section>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { OrganizationPointSettingsConfig } from '@/config/SettingsConfig'
import SmartPointInput from '@/components/form/SmartPointInput.vue'
import AnchorButton from '@/components/form/AnchorButton'
import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'

export default {
  name: 'PointSettings',
  components: {
    AnchorButton,
    InputLabel,
    TextInput,
    SmartPointInput,
  },
  data() {
    return {
      isLoaded: false,
      settings: {},
      counter: 0,
      currentSettingsGroup: null,
      rewardPointGroups: [],
      rewardPointGroupEditMode: false,
      isRewardPointEdit: false,
      dataChangeStatus: false,
    }
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'good') {
          this.settings.bad_points = this.settings.bad_points.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.isRewardPointEdit = false
        }
        if (val === 'bad') {
          this.settings.good_points = this.settings.good_points.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.isRewardPointEdit = false
        }
      },
    },
    rewardPointGroups: {
      deep: true,
      handler() {
        if (this.counter > 0) {
          this.dataChangeStatus = true
        }
        this.counter++
      },
    },
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  async created() {
    const requests = [
      this.$http.get(OrganizationPointSettingsConfig.api.index),
      this.$http.get(OrganizationPointSettingsConfig.api.rewardPointGroupIndex),
    ]

    try {
      const responses = await Promise.allSettled(requests)
      responses.map((response) => {
        if (response.status === 'fulfilled') {
          if (
            response.value.config.url ===
            OrganizationPointSettingsConfig.api.index
          ) {
            this.settings = response.value.data.data
          }
          if (
            response.value.config.url ===
            OrganizationPointSettingsConfig.api.rewardPointGroupIndex
          ) {
            this.rewardPointGroups = response.value.data.data
          }
        }
        if (response.status === 'rejected') {
          let message = ''
          if (
            response.reason.config.url ===
            OrganizationPointSettingsConfig.api.index
          ) {
            message = `Point Settings: ${response.reason.response.data.detail}`
          }
          if (
            response.reason.config.url ===
            OrganizationPointSettingsConfig.api.rewardPointGroupIndex
          ) {
            message = `Reward Settings: ${response.reason.response.data.detail}`
          }

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: `Error [${response.reason.response.status}]`,
              text: message,
            },
            5000
          )
        }
      })
    } catch (err) {
      console.log('Error loading data', { err })
    } finally {
      this.isLoaded = true
    }
  },
  mounted() {
    EventBus.$on('open-edit', (group, id) => {
      console.log('groupid', group, id)
      if (group === 'good') {
        this.settings.good_points = this.settings.good_points.map((item) => {
          if (id === item.id) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'good'
      }
      if (group === 'bad') {
        this.settings.bad_points = this.settings.bad_points.map((item) => {
          if (id === item.id) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'bad'
      }
      if (this.isRewardPointEdit) {
        this.isRewardPointEdit = false
        this.handleRewardPointCancel()
      }
    })
  },
  methods: {
    handleRewardPointEdit() {
      this.settings.good_points = this.settings.good_points.map((item) => ({
        ...item,
        isEdit: false,
      }))

      this.settings.bad_points = this.settings.bad_points.map((item) => ({
        ...item,
        isEdit: false,
      }))
      this.isRewardPointEdit = true
    },
    handleRewardPointCancel() {
      this.isRewardPointEdit = false
      if (this.dataChangeStatus) {
        this.$http
          .get(OrganizationPointSettingsConfig.api.rewardPointGroupIndex)
          .then((res) => {
            this.rewardPointGroups = res.data.data
          })
        this.dataChangeStatus = false
        this.counter = 0
      }
    },
    async onSave(key, obj) {
      let data = new FormData()
      data.append('point', obj.value)

      await this.$http
        .patch(OrganizationPointSettingsConfig.api.update(key), data)
        .then(() => {
          this.settings.good_points = this.settings.good_points.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.settings.bad_points = this.settings.bad_points.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: obj.title,
              text: `The point of "${obj.title}" behaviour is set to ${obj.value}`,
            },
            3000
          )
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
    handleRewardPointGroupInputChange() {
      let lastGroupVal
      this.rewardPointGroups.forEach((item, idx) => {
        if (lastGroupVal) {
          this.rewardPointGroups[idx].start = lastGroupVal + 1
        }

        lastGroupVal = item.end

        if (
          item.end !== null &&
          this.rewardPointGroups[idx].start >= item.end
        ) {
          this.rewardPointGroups[idx].end =
            this.rewardPointGroups[idx].start + 100
        }
      })
    },
    updateRewardPointGroups() {
      const payload = { data: this.rewardPointGroups }
      this.$http
        .post(
          OrganizationPointSettingsConfig.api.rewardPointGroupUpdate,
          payload
        )
        .then(() => {
          this.$notify({
            group: 'bottomRight',
            type: 'success',
            title: `Reward Point Groups Update`,
            text: `Reward point groups has been updated successfully`,
          })
          this.isRewardPointEdit = false
        })
        .catch((err) => {
          console.log(err)

          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Error',
              text: err.response.data.message || 'Failed to update settings',
            },
            5000
          )
        })
    },
    onAddBenefit(groupIdx) {
      if (this.isRewardPointEdit) {
        this.rewardPointGroups[groupIdx].benefits.push('')
      }
    },
    onRemoveBenefit(groupIdx, idx) {
      if (this.isRewardPointEdit) {
        this.rewardPointGroups[groupIdx].benefits.splice(idx, 1)
      }
    },
  },
}
</script>

<style lang="scss">
$border: 1px solid #d5d5d5;
.border-1 {
  border-width: 1px;
}
.w-30p {
  width: 30%;
}
.w-70p {
  width: 70%;
}
.points-table-title {
  background-color: #eeeeee;
  height: 33px;
  @apply flex items-center mt-10;
}
.points-item {
  display: flex;
  align-items: center;
  height: 42px;
  border-left: $border;
  border-right: $border;
  border-bottom: $border;
  font-size: 14px;
  color: #484848;
  font-weight: 400;
  .left {
    width: 70%;
    padding: 8px;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    padding: 8px;
    border-left: $border;
  }
}
</style>
